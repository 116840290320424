import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { AuthGuard } from './helper/auth.guard';

export const AppRoutes: Routes = [
    {
        path: '',
        component: FullComponent,
        canActivate: [AuthGuard],
        data: { someRoles: ['admin', 'vnuhcm.khcn.chuyenvien', 'vnuhcm.khcn.lanhdaoban'] },
        children: [
            {
                path: '',
                redirectTo: '/dashboards',
                pathMatch: 'full',
            },
            {
                path: 'dashboard',
                redirectTo: '/dashboards',
                pathMatch: 'full',
            },
            {
                path: 'dashboards',
                loadChildren: () => import('./dashboards/dashboards.module').then((m) => m.DashboardsModule),
            },

            // Account
            {
                path: 'account',
                loadChildren: () => import('./account/account.module').then((m) => m.AccountModule),
            },
            // Files
            {
                path: 'files',
                loadChildren: () => import('./files/files.module').then((m) => m.FilesModule),
                // canActivate: [AuthGuard],
            },

            // Science Technology
            {
                path: 'science-technology',
                loadChildren: () => import('./science-technology/science-technology.module').then((m) => m.FormModule),
            },
            {
                path: 'researchs',
                loadChildren: () => import('./science-technology/science-technology.module').then((m) => m.FormModule),
            },
            {
                path: 'researchs',
                redirectTo: '/researchs',
                pathMatch: 'full',
            },
            {
                path: 'invention-transfer',
                loadChildren: () => import('./science-technology/science-technology.module').then((m) => m.FormModule),
            },
            {
                path: 'project-management',
                loadChildren: () => import('./science-technology/science-technology.module').then((m) => m.FormModule),
            },
            {
                path: 'cooperation-foreign-affairs',
                loadChildren: () => import('./science-technology/science-technology.module').then((m) => m.FormModule),
            },
            {
                path: 'seminar-management',
                loadChildren: () => import('./science-technology/science-technology.module').then((m) => m.FormModule),
            },

            // Synthetic
            {
                path: 'synthetic',
                loadChildren: () => import('./synthetic/synthetic.module').then((m) => m.SyntheticModule),
            },
        ],
    },
    {
        path: '',
        component: AppBlankComponent,
        children: [
            {
                path: 'authentication',
                loadChildren: () =>
                    import('./authentication/authentication.module').then((m) => m.AuthenticationModule),
            },
        ],
    },
    {
        path: '**',
        redirectTo: 'authentication/404',
    },
];
