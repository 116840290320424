import { Injectable } from '@angular/core';
import { Routes } from '@angular/router';
import { AccountService } from 'src/app/providers/account.service';
export interface BadgeItem {
    type: string;
    value: string;
}
export interface Saperator {
    name: string;
    type?: string;
}
export interface SubChildren {
    state: string;
    name: string;
    type?: string;
}
export interface ChildrenItems {
    state: string;
    name: string;
    type?: string;
    child?: SubChildren[];
}

export interface Menu {
    state: string | string[];
    name: string;
    type: string;
    icon: string;
    badge?: BadgeItem[];
    saperator?: Saperator[];
    children?: ChildrenItems[];
}

export const DASHBOARD_MENUITEMS = {
    state: 'dashboards',
    name: 'Dashboards',
    type: 'link',
    icon: 'av_timer',
};

export const FILES_MENUITEMS = {
    state: 'files',
    name: 'Files',
    type: 'sub',
    icon: 'archive',
    children: [{ state: 'filesdownloaded', name: 'Files Downloaded', type: 'link' }],
};

// export const UTILITIES_MENUITEMS = {
//     state: 'utitlities',
//     name: 'Utitlities',
//     type: 'sub',
//     icon: 'extension',
//     children: [
//         { state: 'utilitiesaddressward', name: 'Ward Address Normalization', type: 'link' },
//         { state: 'utilitiesaddressdistrict', name: 'District Address Normalization', type: 'link' },
//         { state: 'utilitiesaddressprovince', name: 'Province Address Normalization', type: 'link' },
//     ],
// };

export const KHCN = [
    {
        state: 'researchs',
        name: 'de_tai_nghien_cuu',
        type: 'sub',
        icon: 'book',
        children: [
            { state: 'research', name: 'de_tai_nghien_cuu', type: 'link' },
            { state: 'new-research', name: 'them_de_tai', type: 'link' },
            // { state: 'topic-registration', name: 'dang_ky_de_tai', type: 'link' },
        ],
    },
    {
        state: 'invention-transfer',
        name: 'sang_che_va_chuyen_giao',
        type: 'sub',
        icon: 'fingerprint',
        children: [
            { state: 'intellectual-property', name: 'so_huu_tri_tue', type: 'link' },
            { state: 'patent-transfer-registration', name: 'dang_ky_so_huu_tri_tue', type: 'link' },
        ],
    },
    {
        state: 'project-management',
        name: 'quan_ly_du_an',
        type: 'sub',
        icon: 'ballot',
        children: [
            { state: 'project-list', name: 'danh_sach_du_an', type: 'link' },
            { state: 'project-registration', name: 'dang_ky_du_an', type: 'link' },
        ],
    },
    {
        state: 'cooperation-foreign-affairs',
        name: 'hoat_dong_hop_tac',
        type: 'sub',
        icon: 'group',
        children: [
            { state: 'cooperation-local', name: 'hop_tac_doi_ngoai', type: 'link' },
            { state: 'cooperation-f-a-registration', name: 'dang_ky_chuong_trinh', type: 'link' },
        ],
    },
    {
        state: 'seminar-management',
        name: 'quan_ly_hoi_thao',
        type: 'sub',
        icon: 'event_note',
        children: [
            { state: 'seminar', name: 'hoi_thao', type: 'link' },
            { state: 'seminar-registration', name: 'dang_ky_chuong_trinh', type: 'link' },
        ],
    },
];

export const BAO_CAO_TONG_HOP = {
    state: 'synthetic',
    name: 'Reporting',
    type: 'sub',
    icon: 'library_books',
    children: [{ state: 'bao-cao-ban-khcn', name: 'bao_cao_ban_khcn', type: 'link' }],
};

export const ACCOUNT_MENUITEMS = {
    state: 'account',
    name: 'Account',
    type: 'sub',
    icon: 'manage_accounts',
    children: [{ state: 'editaccount', name: 'Edit Account', type: 'link' }],
};

export const MENUITEMS = [DASHBOARD_MENUITEMS, ...KHCN, BAO_CAO_TONG_HOP, FILES_MENUITEMS, ACCOUNT_MENUITEMS];

@Injectable({ providedIn: 'root' })
export class MenuItems {
    constructor(private accountService: AccountService) {}

    getMenuitem(): Menu[] {
        return MENUITEMS;
    }
}

export function updateMenuItemsRole() {}
