import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { LocalSettingService } from './local_setting.service';

export interface Lang {
    language: string;
    code: string;
    icon: string;
}
@Injectable({ providedIn: 'root' })
export class LanguageService {
    public readonly langs: Lang[] = [
        {
            language: 'English',
            code: 'en',
            icon: 'us',
        },
        {
            language: 'Vietnamese',
            code: 'vn',
            icon: 'vn',
        },
    ];

    private _language: Lang = this.langs[1];
    public get language(): Lang {
        return this._language;
    }
    public set language(value: Lang) {
        this._language = value;
    }

    private language_sub: Subject<Lang> = new Subject<Lang>();
    private language_code_sub: Subject<string> = new Subject<string>();

    constructor(private localSettingService: LocalSettingService) {
        this.updateLang(localSettingService.lang);
        localSettingService.langSub().subscribe((update) => this.updateLang(update));
    }

    public getLang(): Lang {
        return this.language;
    }

    public getLanguage() {
        return this.language.code;
    }

    public getLangSub(): Observable<Lang> {
        return this.language_sub.asObservable();
    }

    public getLanguageUpdateListener() {
        return this.language_code_sub.asObservable();
    }

    public setLang(newLang: Lang | string) {
        let code: string;
        if ((<Lang>newLang).code !== undefined) {
            code = (<Lang>newLang).code;
        } else {
            code = <string>newLang;
        }
        this.localSettingService.lang = code;
    }

    private getLangFromCode(code: string): Lang | undefined {
        return this.langs.find((l, i) => l.code === code);
    }

    public updateLang(code: string) {
        this.language = this.getLangFromCode(code)!;
        this.language_sub.next(this.language);
        this.language_code_sub.next(this.language.code);
    }
}
