import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LocalSettingService {
    constructor() {
        this.loadLangFromLocal();
    }

    // #region Language
    private readonly lang_code_arr: string[] = ['en', 'vn'];
    private readonly lang_code_def: string = this.lang_code_arr[0];
    private lang_code: string = this.lang_code_def;
    private lang_code_sub: Subject<string> = new Subject<string>();

    public get lang(): string {
        return this.lang_code;
    }

    public set lang(newLang: string) {
        if (this.lang_code_arr.indexOf(newLang) === -1) throw 'invalid language code!';
        this.setLang(newLang);
    }

    public langSub(): Observable<string> {
        return this.lang_code_sub.asObservable();
    }

    private setLang(newLang: string) {
        this.lang_code = newLang;
        this.lang_code_sub.next(this.lang_code);
        if (newLang !== localStorage.getItem('lang')) {
            localStorage.setItem('lang', newLang);
        }
    }

    private loadLangFromLocal() {
        let localLang = localStorage.getItem('lang');
        if (localLang) this.setLang(localLang);
    }
    // #endregion
}
