<!-- ============================================================== -->
<!-- sidebar -->
<!-- ============================================================== -->
<div class="user-profile" style="background: url(assets/images/background/logo_khcn.png) no-repeat; height: 21vh">
    <!-- User profile image -->
    <!-- <div class="profile-img">
        <img src="assets/images/users/boss.jpg" alt="user" (click)="handleNotify()"> </div> -->
    <!-- User profile text-->
    <!-- ============================================================== -->
    <!-- Profile - style you can find in header.scss -->
    <!-- ============================================================== -->
    <!-- <div class="profile-text">
        <a [matMenuTriggerFor]="sdprofile" class=""> Bùi Quốc Anh
            <i class="ti-angle-down font-12 m-l-5"></i>
        </a>
    </div>
    <mat-menu #sdprofile="matMenu" class="mymegamenu">
        <button mat-menu-item>
            <mat-icon>settings</mat-icon> Settings
        </button>
        <button mat-menu-item>
            <mat-icon>account_box</mat-icon> Profile
        </button>
        <button mat-menu-item>
            <mat-icon>notifications_off</mat-icon> Disable notifications
        </button>
        <button mat-menu-item>
            <mat-icon >exit_to_app</mat-icon> Sign Out
        </button>
    </mat-menu> -->
</div>

<mat-nav-list appAccordion>
    <mat-list-item
        appAccordionLink
        *ngFor="let menuitem of menuItems.getMenuitem()"
        routerLinkActive="selected"
        group="{{ menuitem.state }}"
        (click)="scrollToTop()"
    >
        <a
            class=""
            appAccordionToggle
            [routerLink]="createRouterLink(menuitem.state)"
            *ngIf="menuitem.type === 'link'"
            (click)="handleNotify(menuitem.name)"
        >
            <mat-icon>{{ menuitem.icon }}</mat-icon>
            <span>{{ menuitem.name | translate }}</span>
            <span fxFlex></span>
            <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
        </a>

        <a class="" appAccordionToggle href="{{ menuitem.state }}" *ngIf="menuitem.type === 'extLink'">
            <mat-icon>{{ menuitem.icon }}</mat-icon>
            <span>{{ menuitem.name | translate }}</span>
            <span fxFlex></span>
            <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
        </a>

        <a
            class=""
            appAccordionToggle
            href="{{ menuitem.state }}"
            target="_blank"
            *ngIf="menuitem.type === 'extTabLink'"
        >
            <mat-icon>{{ menuitem.icon }}</mat-icon>
            <span>{{ menuitem.name | translate }}</span>
            <span fxFlex></span>
            <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
        </a>

        <a class="" appAccordionToggle href="javascript:;" *ngIf="menuitem.type === 'sub'">
            <mat-icon>{{ menuitem.icon }}</mat-icon>
            <span>{{ menuitem.name | translate }}</span>
            <span fxFlex></span>
            <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
            <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
        </a>

        <mat-nav-list class="sub-item" *ngIf="menuitem.type === 'sub'">
            <mat-list-item
                *ngFor="let childitem of menuitem.children; let j = index; let i = childitem"
                routerLinkActive="selected"
            >
                <a
                    [routerLink]="createRouterLink(menuitem.state, childitem.state)"
                    *ngIf="childitem.type === 'link'"
                    class="relative"
                    routerLinkActive="selected"
                    (click)="itemSelect[i] = j"
                    (click)="handleNotify(childitem.name)"
                >
                    {{ childitem.name | translate }}
                </a>
            </mat-list-item>

            <mat-list-item *ngFor="let childitem of menuitem.children; let j = index; let i = childitem">
                <a
                    class=""
                    href="javascript: void(0);"
                    *ngIf="childitem.type === 'subchild'"
                    (click)="itemSelect[i] = j"
                    [ngClass]="j == itemSelect[i] ? 'selected' : ''"
                >
                    <span>{{ childitem.name | translate }}</span>
                    <span fxFlex></span>
                    <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
                </a>

                <mat-nav-list class="child-sub-item" *ngIf="childitem.type === 'subchild'">
                    <mat-list-item *ngFor="let child of childitem.subchildren" routerLinkActive="selected">
                        <a
                            [routerLink]="createRouterLink(menuitem.state, childitem.state, child.state)"
                            routerLinkActive="selected"
                            >{{ child.name | translate }}</a
                        >
                    </mat-list-item>
                </mat-nav-list>
            </mat-list-item>
        </mat-nav-list>

        <div class="saperator text-muted" *ngIf="menuitem.type === 'saperator'">
            <span>{{ menuitem.name | translate }}</span>
        </div>
    </mat-list-item>
</mat-nav-list>
